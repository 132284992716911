<template>
  <div
    :class="{
      'e-chat__item--opponent': type !== 'user',
      'e-chat__item--user': type === 'user'
    }"
    class="e-chat__item"
  >
    <div class="e-chat__item-aside">
      <div class="e-chat__avatar">
        <icon-user />
      </div>
    </div>

    <div class="e-chat__item-content">
      <template v-for="(msg, index) in messages">
        <chat-message
          :key="index"
          :message="msg"
          :deletable="type === 'user' && !msg.deleted && !readOnly"
        />
      </template>
    </div>
  </div>
</template>

<script>
import ChatMessage from 'atoms/ChatMessage/ChatMessage'
import IconUser from 'assets/icons/user.svg'

export default {
  components: {
    ChatMessage,
    IconUser
  },

  props: {
    type: {
      default: 'admin',
      type: String,
      validator: (val) => {
        return ['opponent', 'user'].indexOf(val) !== -1
      }
    },
    messages: {
      default: () => [],
      type: Array
    },
    readOnly: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/ChatItem';
</style>
